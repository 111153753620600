import * as HeaderNavigationMobileStyled from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/HeaderNavigationMobile.styled';
import { useState } from 'react';
import * as UserDropdownStyles from 'components/Layouts/Headers/HeaderComponents/UserDropdown/UserDropdown.styled';
import { CloseOutlineIc } from '@dsch/dd-icons';

import { UserDropdownProps } from 'components/Layouts/Headers/HeaderComponents/UserDropdown/UserDropdown.typed';
import {
  getHelpItem,
  getMenuItems,
} from 'components/Layouts/components/UserMenuItems.helper';
import type { MenuItem } from 'components/Toolkit/Menu/Menu';

const UserDropdown = (props: UserDropdownProps) => {
  const { onClose, selected, user, messages, historyChecks, onUserLogin } =
    props;

  const [showMore, setShowMore] = useState(true);

  const menuItems: MenuItem[] = getMenuItems({
    selectedSection: selected,
    historyChecks,
    messages,
    showMore: !showMore,
  });

  menuItems.push(getHelpItem(showMore));

  if (user) {
    return (
      <>
        <UserDropdownStyles.SubList>
          <UserDropdownStyles.ItemTitleWrapper>
            <UserDropdownStyles.ItemTitle>
              <span>{user.name}</span>
              <UserDropdownStyles.ItemTitleButton
                onClick={() => user.onUserLogOut()}
                data-tracking="header-nav"
              >
                Log out
              </UserDropdownStyles.ItemTitleButton>
            </UserDropdownStyles.ItemTitle>
            <UserDropdownStyles.Close
              onClick={onClose}
              data-testid="modal-close"
            >
              <CloseOutlineIc width="16" height="16" />
            </UserDropdownStyles.Close>
          </UserDropdownStyles.ItemTitleWrapper>

          {menuItems &&
            menuItems.map((item, index) => {
              return (
                <UserDropdownStyles.Item
                  key={index}
                  itemHidden={item.itemHidden}
                >
                  <UserDropdownStyles.LinkWrapper>
                    <UserDropdownStyles.Link
                      href={item.link}
                      data-tracking="header-nav"
                    >
                      {item.icon && item.icon && (
                        <UserDropdownStyles.IconItem>
                          {item.icon}
                        </UserDropdownStyles.IconItem>
                      )}
                      <UserDropdownStyles.ItemLabel>
                        {item.displayName}
                      </UserDropdownStyles.ItemLabel>
                    </UserDropdownStyles.Link>
                    {item.notifications && (
                      <UserDropdownStyles.Badge count={item.notifications} />
                    )}
                  </UserDropdownStyles.LinkWrapper>
                </UserDropdownStyles.Item>
              );
            })}

          <UserDropdownStyles.ItemToggle onClick={() => setShowMore(!showMore)}>
            {showMore ? 'See more' : 'See less'}
          </UserDropdownStyles.ItemToggle>
        </UserDropdownStyles.SubList>
        <HeaderNavigationMobileStyled.Separator />
      </>
    );
  } else {
    return (
      <>
        <UserDropdownStyles.ItemTitleWrapper>
          <UserDropdownStyles.ItemTitle>
            <span>Browse DoneDeal</span>
            <UserDropdownStyles.ItemTitleButton
              onClick={onUserLogin}
              data-tracking="header-nav"
            >
              Sign up or Log in
            </UserDropdownStyles.ItemTitleButton>
          </UserDropdownStyles.ItemTitle>
          <UserDropdownStyles.Close onClick={onClose} data-testid="modal-close">
            <CloseOutlineIc width="16" height="16" />
          </UserDropdownStyles.Close>
        </UserDropdownStyles.ItemTitleWrapper>

        <HeaderNavigationMobileStyled.Separator />
      </>
    );
  }
};

export { UserDropdown };
