import styled from 'styled-components';
import { HeaderDropdownProps } from 'components/Layouts/Headers/HeaderComponents/HeaderDropdown';

const ListWrapper = styled.div<Pick<HeaderDropdownProps, 'posX'>>`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  position: absolute;
  top: 52px;
  right: ${({ posX }) => posX}px;
  min-width: 240px;
`;

const List = styled.ul`
  box-shadow: ${({ theme }) => theme.getOuterShadow(theme.colors.BLACK)};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.S8} 0px 0px 0px;
  gap: ${({ theme }) => theme.spacing.S8};
  padding: ${({ theme }) => theme.spacing.M16};
`;

const ListItemLink = styled.a`
  display: flex;
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  margin: 0;
  padding: ${({ theme }) => theme.spacing.S8};
  :hover {
    border-radius: ${({ theme }) => theme.borderRadius.default};
    background-color: ${({ theme }) => theme.colors.GREY_LIGHT};
  }
`;

const ListItemButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => theme.spacing.S8};
  cursor: pointer;
  :hover {
    border-radius: ${({ theme }) => theme.borderRadius.default};
    background-color: ${({ theme }) => theme.colors.GREY_LIGHT};
  }
`;

const ListItemIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacing.S8};
`;

const Separator = styled.span`
  height: 1px;
  width: 100%;
  margin: 0 auto;
  display: block;
  background-color: ${({ theme }) => theme.colors.GREY_LIGHT};
`;

export {
  Separator,
  List,
  ListItemLink,
  ListItemIcon,
  ListItemButton,
  ListWrapper,
};
