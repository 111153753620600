import { ReactNode } from 'react';
import styled from 'styled-components';
import { VERTICAL } from 'helpers/verticals';

interface BaseHeaderProps {
  baseUrl: string;
  vertical: string;
  children: ReactNode;
  cdnUrl: string;
  variant: string;
}

const Wrapper = styled.header`
  padding: ${({ theme }) => `${theme.spacing.S8} 0`};
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 840px) {
    padding: ${({ theme }) => `${theme.spacing.S12} 0`};
  }
`;

export const Right = styled.div`
  display: flex;
  align-content: end;
  margin-left: auto;

  > * {
    margin-left: ${({ theme }) => `${theme.spacing.S12}`};

    @media (min-width: 840px) {
      margin-left: ${({ theme }) => `${theme.spacing.M24}`};
    }
  }
`;

const LogoWrapper = styled.a<{ vertical: string }>`
  display: block;
  height: 32px;
  position: relative;
  width: ${({ vertical }) =>
    vertical === VERTICAL.FARMING
      ? '109px'
      : vertical === VERTICAL.MOTOR
      ? '124px'
      : '72px'};

  @media (min-width: 341px) {
    width: ${({ vertical }) =>
      vertical === VERTICAL.FARMING
        ? '126px'
        : vertical === VERTICAL.MOTOR
        ? '144px'
        : '83px'};
  }

  @media (min-width: 840px) {
    width: ${({ vertical }) =>
      vertical === VERTICAL.FARMING
        ? '180px'
        : vertical === VERTICAL.MOTOR
        ? '206px'
        : '119PX'};
  }
`;

const Logo = styled.img`
  height: 100%;
  width: 100%;
`;

function BaseHeader({ children, vertical, cdnUrl, variant }: BaseHeaderProps) {
  return (
    <Wrapper>
      <div>
        <LogoWrapper href={'/'} vertical={vertical} data-testid="header-logo">
          {vertical === 'farming' && (
            <Logo
              src={`${cdnUrl}/images/logos/donedeal-logo-${
                variant === 'TRANSPARENT' ? 'white-' : ''
              }farming.svg`}
              alt="DoneDeal Farming"
            />
          )}

          {vertical === 'motor' && (
            <Logo
              src={`${cdnUrl}/images/logos/donedeal-logo-${
                variant === 'TRANSPARENT' ? 'white-' : ''
              }motor.svg`}
              alt="DoneDeal Motor"
            />
          )}

          {vertical !== 'farming' && vertical !== 'motor' && (
            <Logo
              src={`${cdnUrl}/images/logos/donedeal-logo-${
                variant === 'TRANSPARENT' ? 'white' : 'red'
              }.svg`}
              alt="DoneDeal Marketplace"
            />
          )}
        </LogoWrapper>
      </div>

      <Right id="base-header-wrapper">{children}</Right>
    </Wrapper>
  );
}

export { BaseHeader };
