import { Fragment } from 'react';
import styled from 'styled-components';
import {
  HeartIc,
  LogoFacebookIc,
  LogoTwitterIc,
  LogoYoutubeIc,
  LogoLinkedinIc,
  LogoInstagramIc,
} from '@dsch/dd-icons';

import {
  mobileLinks,
  exploreLinks,
  ourFriendsLinks,
  customerHappinessLinks,
  aboutUsLinks,
  businessLinks,
} from 'components/Layouts/Footer/footerLinks';

import { media } from 'helpers/breakpoints';
import { Button } from 'components/Toolkit/Button/Button';
import { Link } from 'components/Toolkit/Button/Link';
import { LinkButton } from 'components/Toolkit/Button/LinkButton';
import { FooterLink } from 'components/Layouts/Footer/components/FooterLink';
import { useAuthOptions } from 'hooks/useAuthOptions';

export interface IFooter {
  isLoggedIn: boolean;
  baseUrl?: string;
  cdnUrl: string;
  dataRequestsUrl: string;
}

const Wrapper = styled.footer`
  border-top: ${({ theme }) => `${theme.spacing.S4} solid ${theme.colors.RED}`};
  background-color: ${({ theme }) => theme.colors.GREY_DARKER};
`;

const Container = styled.div`
  width: 100%;

  padding: ${({ theme }) =>
    `${theme.spacing.M24} ${theme.spacing.M16} ${theme.spacing.XL72}`};

  ${media.large} {
    padding: ${({ theme }) => `${theme.spacing.L40} 0 ${theme.spacing.M24}`};
  }
`;

const MainFooter = styled.div`
  max-width: 480px;
  margin: 0 auto;

  ${media.large} {
    max-width: 100%;
    display: grid;
    grid-template-columns: 260px 1fr 160px;
    column-gap: ${({ theme }) => theme.spacing.M24};
  }
`;

const BrandingSection = styled.div``;

const LinkSection = styled.div`
  ${media.large} {
    margin-left: 48px;
  }
  @media screen and (min-width: 1200px) {
    margin-left: 96px;
  }
`;

const AppSection = styled.div``;

const LogoWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.M16};
  text-align: center;
`;

const Logo = styled.img`
  width: 200px;
  height: auto;
  color: ${({ theme }) => theme.colors.WHITE};

  &:visited,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.WHITE};
  }

  @media (min-width: 414px) {
    width: 100%;
    max-width: 268px;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.spacing.M24};
`;

const SmallLogoWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacing.S4};
  display: flex;
  justify-content: center;
  width: 62px;
  height: 24px;
`;

const LogoMessage = styled.div`
  display: flex;
  align-items: center;
  margin: 0 auto;
`;

const Message = styled.p`
  ${({ theme }) => theme.fontSize.M14};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.WHITE};
`;

const StyledHeart = styled(HeartIc)`
  color: ${({ theme }) => theme.colors.RED};
`;

const SocialMediaIconContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: ${({ theme }) => theme.spacing.M24};
  color: ${({ theme }) => theme.colors.WHITE};
`;

const SocialMediaLink = styled.a`
  color: ${({ theme }) => theme.colors.WHITE};
  height: 24px;
  width: auto;

  &:visited,
  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.WHITE};
  }
`;

const SButton = styled(Button)`
  margin-bottom: ${({ theme }) => theme.spacing.M24};
`;

const MobileLinksWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: ${({ theme }) => theme.spacing.M16};
  row-gap: ${({ theme }) => theme.spacing.S8};
  align-items: flex-end;
  margin-bottom: ${({ theme }) => theme.spacing.M24};

  ${media.large} {
    display: none;
  }
`;

const MobileLink = styled(Link)`
  width: fit-content;
`;

const MobileButton = styled(LinkButton)`
  width: fit-content;
`;

const DesktopLinksWrapper = styled.div`
  display: none;

  @media screen and (min-width: 1024px) and (max-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: ${({ theme }) => theme.spacing.M32};
    justify-content: flex-end;
  }

  @media screen and (min-width: 1200px) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    column-gap: ${({ theme }) => theme.spacing.XL72};
    justify-content: flex-end;
  }
`;

const LinksColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinksContainer = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.S8};

  &:last-child {
    margin-bottom: 0;
  }
`;

const AppStoreBadgeContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing.M24};

  & > a {
    margin-right: ${({ theme }) => theme.spacing.S8};
  }

  & > a:last-child {
    margin-right: 0;
  }

  ${media.large} {
    flex-direction: column;

    & > a {
      margin-right: 0;
      margin-bottom: ${({ theme }) => theme.spacing.S8};
    }

    & > a:last-child {
      margin-bottom: 0;
    }
  }
`;

const AppStoreBadgeImage = styled.img`
  width: 140px;
  height: 40px;

  ${media.large} {
    width: 160px;
    height: 46px;
  }
`;

const AppStoreSubHeading = styled.p`
  display: none;

  ${media.large} {
    display: block;
    ${({ theme }) => theme.fontSize.M16};
    margin-bottom: ${({ theme }) => theme.spacing.S8};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme }) => theme.colors.WHITE};
  }
`;

const BottomFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.medium} {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const AllRightsReserved = styled(Link)`
  margin-bottom: ${({ theme }) => theme.spacing.S8};

  ${media.medium} {
    order: 2;
  }
`;

const LegalPrivacy = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.medium} {
    order: 1;
    justify-content: flex-start;

    & > a {
      margin-right: ${({ theme }) => theme.spacing.M24};
    }
  }
`;

function Footer({ isLoggedIn, baseUrl, cdnUrl, dataRequestsUrl }: IFooter) {
  const { handleLogin } = useAuthOptions();

  const signIn = () => handleLogin();

  return (
    <Wrapper>
      <div className="row small-collapse medium-uncollapse">
        <div className="columns small-12">
          <Container>
            <MainFooter>
              <BrandingSection>
                <LogoWrapper>
                  <a href={baseUrl}>
                    <Logo
                      src={`${cdnUrl}/images/logos/donedeal-logo-white-footer.svg`}
                      alt="Donedeal Logo"
                      width="200"
                      height="54"
                    />
                  </a>
                </LogoWrapper>
                <MessageWrapper>
                  <LogoMessage>
                    <SmallLogoWrapper>
                      <Logo
                        src={`${cdnUrl}/images/logos/donedeal-logo-white.svg`}
                        alt="Donedeal Message Logo"
                        width="62"
                        height="24"
                      />
                    </SmallLogoWrapper>
                    <Message>Ltd., Ireland</Message>
                  </LogoMessage>
                  <Message>
                    Made with <StyledHeart width="1em" height="1em" /> in the
                    Sunny South-East
                  </Message>
                </MessageWrapper>
                <SocialMediaIconContainer>
                  <SocialMediaLink
                    href="https://www.facebook.com/DoneDealIreland"
                    target="_blank"
                    rel="noopener"
                  >
                    <LogoFacebookIc />
                  </SocialMediaLink>
                  <SocialMediaLink
                    href="https://www.twitter.com/donedeal"
                    target="_blank"
                    rel="noopener"
                  >
                    <LogoTwitterIc />
                  </SocialMediaLink>
                  <SocialMediaLink
                    href="https://www.youtube.com/user/DoneDealers"
                    target="_blank"
                    rel="noopener"
                  >
                    <LogoYoutubeIc />
                  </SocialMediaLink>
                  <SocialMediaLink
                    href="https://www.linkedin.com/company/donedeal-ie/"
                    target="_blank"
                    rel="noopener"
                  >
                    <LogoLinkedinIc />
                  </SocialMediaLink>
                  <SocialMediaLink
                    href="https://www.instagram.com/donedeal_motors/"
                    target="_blank"
                    rel="noopener"
                  >
                    <LogoInstagramIc />
                  </SocialMediaLink>
                </SocialMediaIconContainer>
                {!isLoggedIn && <SButton onClick={signIn}>Sign in</SButton>}
              </BrandingSection>
              <LinkSection className="large-offset-1">
                <MobileLinksWrapper>
                  {mobileLinks(dataRequestsUrl, baseUrl).map(
                    ({ display, href, external, isLink, onClick }, index) => (
                      <Fragment key={index}>
                        {isLink ? (
                          <MobileLink
                            href={href}
                            target={external ? '_blank' : undefined}
                            rel={external ? 'noopener noreferrer' : undefined}
                            ofType="SECONDARY"
                            colorVariant="GREY_LIGHT"
                            fontSize="M16"
                          >
                            {display}
                          </MobileLink>
                        ) : (
                          <MobileButton
                            onClick={onClick}
                            ofType="SECONDARY"
                            colorVariant="GREY_LIGHT"
                            fontSize="M16"
                          >
                            {display}
                          </MobileButton>
                        )}
                      </Fragment>
                    ),
                  )}
                </MobileLinksWrapper>
                <DesktopLinksWrapper>
                  <LinksColumn>
                    <LinksContainer>
                      {exploreLinks(baseUrl).map((props, index) => (
                        <FooterLink key={index} {...props} />
                      ))}
                    </LinksContainer>
                    <LinksContainer>
                      {ourFriendsLinks.map((props, index) => (
                        <FooterLink key={index} {...props} />
                      ))}
                    </LinksContainer>
                  </LinksColumn>
                  <LinksColumn>
                    <LinksContainer>
                      {customerHappinessLinks(dataRequestsUrl).map(
                        (props, index) => (
                          <FooterLink key={index} {...props} />
                        ),
                      )}
                    </LinksContainer>
                  </LinksColumn>
                  <LinksColumn>
                    <LinksContainer>
                      {aboutUsLinks.map((props, index) => (
                        <FooterLink key={index} {...props} />
                      ))}
                    </LinksContainer>
                    <LinksContainer>
                      {businessLinks.map((props, index) => (
                        <FooterLink key={index} {...props} />
                      ))}
                    </LinksContainer>
                  </LinksColumn>
                </DesktopLinksWrapper>
              </LinkSection>
              <AppSection>
                <AppStoreBadgeContainer>
                  <AppStoreSubHeading>Download our App</AppStoreSubHeading>
                  <a href="https://itunes.apple.com/ie/app/donedeal.ie/id428330596?mt=8">
                    <AppStoreBadgeImage
                      src={`${cdnUrl}/images/logos/download-on-the-app-store-apple-1@2x.png`}
                      alt="Apple App Store badge"
                      width="140"
                      height="40"
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=ie.donedeal.android">
                    <AppStoreBadgeImage
                      src={`${cdnUrl}/images/logos/google-play-badge@2x.png`}
                      alt="Google Play badge"
                      width="140"
                      height="40"
                    />
                  </a>
                </AppStoreBadgeContainer>
              </AppSection>
            </MainFooter>
            <BottomFooter>
              <AllRightsReserved
                href="https://hello.donedeal.ie/hc/en-us/articles/201251771-Copyright-Notice-Legal-Disclaimer-and-General-Terms-2-Feb-2010"
                target="_blank"
                rel="noopener"
                ofType="SECONDARY"
                fontSize="B14"
                colorVariant="GREY"
              >
                © 2005-{new Date().getFullYear()} All Rights Reserved
              </AllRightsReserved>
              <LegalPrivacy>
                <Link
                  href="https://hello.donedeal.ie/hc/en-us/articles/201251771-Copyright-Notice-Legal-Disclaimer-and-General-Terms-2-Feb-2010"
                  target="_blank"
                  rel="noopener"
                  ofType="SECONDARY"
                  fontSize="B14"
                  colorVariant="GREY"
                >
                  Legal
                </Link>
                <Link
                  href="https://hello.donedeal.ie/hc/en-us/articles/201251721-Privacy-Policy"
                  target="_blank"
                  rel="noopener"
                  ofType="SECONDARY"
                  fontSize="B14"
                  colorVariant="GREY"
                >
                  Privacy & Cookies
                </Link>
              </LegalPrivacy>
            </BottomFooter>
          </Container>
        </div>
      </div>
    </Wrapper>
  );
}

export { Footer };
