import React, { ReactElement, ReactNode } from 'react';
import { NavigationItem } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/NavigationItem/NavigationItem';
import { NavigationItemProps } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/NavigationItem/NavigationItem.typed';

import * as NavigationStyles from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/Navigation/Navigation.styled';
import { useNavigation } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/Navigation/Navigation.hook';

export const Navigation = (props: {
  items?: NavigationItemProps[];
  children: (params: {
    setNavigationOpen: (isOpen: boolean) => void;
    isOpen: boolean;
  }) => ReactElement;
  className?: string;
  renderItem?: (item: NavigationItemProps) => ReactNode;
  subMenuWidth?: number;
}): React.ReactElement => {
  const { items, children, className, renderItem, subMenuWidth } = props;

  const {
    childrenProps,
    navigationIsOpen,
    rootMenuRef,
    containerRef,
    closeNavigation,
  } = useNavigation();

  return (
    <NavigationStyles.Wrapper onMouseLeave={closeNavigation}>
      <NavigationStyles.ElementWrapper className={className} ref={containerRef}>
        {children(childrenProps)}
      </NavigationStyles.ElementWrapper>
      {navigationIsOpen && items && (
        <NavigationStyles.ListWrapper>
          <NavigationStyles.List ref={rootMenuRef} role="menubar">
            {items.map((item, index) => (
              <NavigationItem
                key={index}
                option={item}
                renderItem={renderItem}
                subMenuWidth={subMenuWidth}
              />
            ))}
          </NavigationStyles.List>
        </NavigationStyles.ListWrapper>
      )}
    </NavigationStyles.Wrapper>
  );
};
