import { useEffect, useState, useRef, forwardRef } from 'react';
import { PersonCircleOutlineIc } from '@dsch/dd-icons';
import { theme } from 'helpers/Theme';
import { HeaderUserDropdownProfileProps } from 'components/Layouts/Headers/HeaderComponents/HeaderUserDropdownProfile/HeaderUserDropdownProfile.typed';
import * as Styled from 'components/Layouts/Headers/HeaderComponents/HeaderUserDropdownProfile/HeaderUserDropdownProfile.styled';

const HeaderUserDropdownProfile = forwardRef<
  HTMLButtonElement,
  HeaderUserDropdownProfileProps
>(
  (
    {
      username,
      notificationCount,
      posX,
      isActive,
      handleActive,
      variant,
      className,
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLDivElement | null>(null);
    const [isMounted, setIsMounted] = useState<boolean>(true);

    useEffect(() => {
      function updatePos() {
        if (innerRef?.current) {
          posX(innerRef.current.getBoundingClientRect().right);
        }
      }
      if (isActive) {
        window.addEventListener('resize', updatePos);
        updatePos();
        handleActive();
      } else {
        if (isMounted) {
          setIsMounted(false);
        } else {
          window.removeEventListener('resize', updatePos);
          handleActive();
        }
      }
      // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
      // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
      // the lint rule's judgement.
      // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive]);

    const showNotification = Boolean(
      notificationCount && notificationCount > 0,
    );

    return (
      <Styled.Container ref={innerRef} className={className}>
        <Styled.Wrapper
          ref={ref}
          data-testid="header-dropdown-btn"
          isOpen={isActive}
          variant={variant}
        >
          <Styled.Profile>
            {showNotification && <Styled.Badge />}
            <PersonCircleOutlineIc
              color={
                variant === 'TRANSPARENT'
                  ? theme.colors.WHITE
                  : theme.colors.GREY_DARKER
              }
              width="24"
              height="24"
            />
          </Styled.Profile>

          <Styled.Name data-testid="user-name" variant={variant}>
            {username}
          </Styled.Name>
        </Styled.Wrapper>
      </Styled.Container>
    );
  },
);

HeaderUserDropdownProfile.displayName = 'HeaderUserProfile';

export { HeaderUserDropdownProfile };
