import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { UserMenu } from 'components/Layouts/components/UserMenu';

export interface HeaderDropdownProps {
  /** Current state of dropdown */
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  username: string;
  cdnUrl: string;
  count?: string;
  unreadMessageCount?: number;
  unreadHistoryCheckCount?: number;
  posX: number;
  onLogin: () => void;
  logout: () => void;
}

const DropDownList = styled.div<Pick<HeaderDropdownProps, 'posX'>>`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  box-shadow: ${({ theme }) => theme.getOuterShadow(theme.colors.BLACK)};
  background-color: ${({ theme }) => theme.colors.WHITE};
  position: absolute;
  top: ${({ theme }) => theme.spacing.L48};
  right: ${({ posX }) => posX}px;
  padding: ${({ theme }) => `${theme.spacing.S8} 0 ${theme.spacing.S4}`};
  min-width: 188px;
`;

const HeaderDropdown = forwardRef<HTMLDivElement, HeaderDropdownProps>(
  (
    {
      isOpen,
      setIsOpen,
      username,
      unreadMessageCount,
      unreadHistoryCheckCount,
      posX,
      onLogin,
      logout,
    },
    ref,
  ) => {
    const handleLogin = () => {
      setIsOpen(false);
      onLogin();
    };

    const handleLogout = () => {
      setIsOpen(false);
      logout();
    };

    return isOpen ? (
      <DropDownList posX={posX} ref={ref} data-testid="header-dropdown-menu">
        <UserMenu
          messages={unreadMessageCount}
          historyChecks={unreadHistoryCheckCount}
          user={{
            isUserLogged: !!username,
            onUserLogOut: handleLogout,
            onUserLogIn: handleLogin,
          }}
          help
        />
      </DropDownList>
    ) : null;
  },
);

HeaderDropdown.displayName = 'HeaderDropdown';

export { HeaderDropdown };
