import styled from 'styled-components';

import { Link } from 'components/Toolkit/Button/Link';
import { LinkButton } from 'components/Toolkit/Button/LinkButton';

interface IFooterLink {
  display: string;
  href?: string;
  external?: boolean;
  isLink?: boolean;
  isHeading?: boolean;
  onClick?: () => void;
}

const LinkHeading = styled.p`
  ${({ theme }) => theme.fontSize.M16};
  margin-bottom: ${({ theme }) => theme.spacing.S12};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.GREY_LIGHT};
  white-space: nowrap;
`;

const DesktopLink = styled(Link)`
  width: fit-content;
  padding-left: ${({ theme }) => theme.spacing.S12};
  margin-bottom: ${({ theme }) => theme.spacing.M16};
`;

const DesktopButton = styled(LinkButton)`
  width: fit-content;
  padding-left: ${({ theme }) => theme.spacing.S12};
`;

function FooterLink({
  display,
  href,
  external,
  isHeading,
  isLink,
  onClick,
}: IFooterLink) {
  return (
    <>
      {isHeading ? (
        <LinkHeading>{display}</LinkHeading>
      ) : isLink ? (
        <DesktopLink
          href={href}
          target={external ? '_blank' : undefined}
          rel={external ? 'noopener noreferrer' : undefined}
          ofType="SECONDARY"
          colorVariant="GREY_LIGHT"
          fontSize="B14"
        >
          {display}
        </DesktopLink>
      ) : (
        <DesktopButton
          onClick={onClick}
          ofType="SECONDARY"
          colorVariant="GREY_LIGHT"
          fontSize="B14"
        >
          {display}
        </DesktopButton>
      )}
    </>
  );
}

export { FooterLink };
