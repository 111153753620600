import { useEffect } from 'react';
import Modal, { Props } from 'react-modal';
import { disableScroll, enableScroll } from 'helpers/scroll';
import * as HeaderNavigationMobileStyled from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/HeaderNavigationMobile.styled';
import { Navigation } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/components/Navigation/Navigation';
import { UserDropdown } from 'components/Layouts/Headers/HeaderComponents/UserDropdown/UserDropdown';
import { HeaderNavigationMobileProps } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/HeaderNavigationMobile.typed';
import { useAuthOptions } from 'hooks/useAuthOptions';

export interface BottomSheetProps extends HeaderNavigationMobileProps, Props {}

// http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#__next');

const HeaderNavigationMobile = (props: BottomSheetProps) => {
  const {
    askToClose,
    isOpen,
    unreadMessageCount,
    unreadHistoryCheckCount,
    onLogin,
    logout,
    username,
    navigation,
  } = props;

  useEffect(() => {
    isOpen && disableScroll();
    return () => {
      enableScroll();
    };
  }, [isOpen]);

  const handleLogin = () => {
    onLogin();
  };

  const handleLogout = () => {
    logout();
  };

  const { isAuthenticated } = useAuthOptions();

  return (
    <>
      <HeaderNavigationMobileStyled.OverlayStyle />
      <HeaderNavigationMobileStyled.Dialog
        height={'100vh'}
        isOpen={isOpen}
        overlayClassName="bottom-sheet-overlay"
        onRequestClose={askToClose}
        closeTimeoutMS={200}
        testId={'navigation-burger-menu'}
      >
        <HeaderNavigationMobileStyled.DialogInner data-testid="bottom-sheet-list">
          <HeaderNavigationMobileStyled.DialogBody>
            <HeaderNavigationMobileStyled.DialogWrapper
              data-testid={'header-burger-menu'}
            >
              <UserDropdown
                isLoggIn={false}
                onClose={askToClose}
                messages={unreadMessageCount}
                historyChecks={unreadHistoryCheckCount}
                user={
                  isAuthenticated
                    ? {
                        name: username,
                        onUserLogOut: handleLogout,
                      }
                    : undefined
                }
                onUserLogin={handleLogin}
              />

              {navigation.map((navigationItem, index) => {
                if (navigationItem.items) {
                  return (
                    <Navigation
                      key={index}
                      label={navigationItem.label}
                      items={navigationItem.items}
                      onClose={askToClose}
                    />
                  );
                } else {
                  return (
                    <HeaderNavigationMobileStyled.MainLink
                      role="button"
                      variant={'DEFAULT'}
                      isOpen={false}
                      href={navigationItem.link}
                      key={index}
                    >
                      <HeaderNavigationMobileStyled.MainButtonText>
                        {navigationItem.label}
                      </HeaderNavigationMobileStyled.MainButtonText>
                    </HeaderNavigationMobileStyled.MainLink>
                  );
                }
              })}
            </HeaderNavigationMobileStyled.DialogWrapper>
          </HeaderNavigationMobileStyled.DialogBody>
        </HeaderNavigationMobileStyled.DialogInner>
      </HeaderNavigationMobileStyled.Dialog>
    </>
  );
};

export { HeaderNavigationMobile };
