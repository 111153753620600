import styled, { css } from 'styled-components';
import { Link as ToolkitLink } from 'components/Toolkit/Button/Link';
import { BaseBadge } from 'components/Toolkit/BaseBadge/BaseBadge';
import { LinkButton } from 'components/Toolkit/Button/LinkButton';

const ItemStyles = css`
  ${({ theme }) => theme.fontSize.L18};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => theme.spacing.S8} 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  padding: ${({ theme }) => theme.spacing.M16} 0;
`;

const Badge = styled(BaseBadge)`
  margin: auto 0 auto ${({ theme }) => theme.spacing.S8};
  display: flex;
  height: ${({ theme }) => theme.spacing.M16};
  padding: 0 ${({ theme }) => theme.spacing.S8};
  width: auto;
  ${({ theme }) => theme.fontSize.S12};
`;

const SubList = styled.ul`
  background-color: #ffffff;
  flex-direction: column;
  width: 100%;
  box-shadow: none;
  display: flex;
  overflow-y: scroll;
  padding-bottom: ${({ theme }) => theme.spacing.M24};
`;

const Link = styled(ToolkitLink)`
  :hover {
    text-decoration: none;
  }
`;

const ItemLink = styled.a`
  padding: 0 ${({ theme }) => theme.spacing.M24}
    ${({ theme }) => theme.spacing.M16} 0;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const Item = styled.li<{ itemHidden?: boolean }>`
  display: ${({ itemHidden }) => (itemHidden ? 'none' : 'flex')};
  ${Link} {
    display: flex;
    cursor: pointer;
    word-break: break-word;
    width: 100%;
    align-items: center;
    white-space: nowrap;
    ${({ theme }) => theme.fontSize.M16};
    color: ${({ theme }) => theme.colors.GREY_DARKER};
    font-weight: ${({ theme }) => theme.fontWeight.regular};
    padding: ${({ theme }) => theme.spacing.S8} 0;

    :hover,
    :active,
    :focus {
      color: ${({ theme }) => theme.colors.GREY_DARKER};
    }
  }
`;

const ItemToggle = styled.li`
  ${ItemStyles};
  ${({ theme }) => theme.fontSize.M16};
  font-weight: ${({ theme }) => theme.fontWeight.semibold};
  text-decoration: underline;
  cursor: pointer;
  padding: ${({ theme }) => theme.spacing.S8} 0 0;
`;

const Close = styled.span`
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  cursor: pointer;
  top: ${({ theme }) => theme.spacing.M24};
  right: ${({ theme }) => theme.spacing.M24};
  position: absolute;
  width: ${({ theme }) => theme.spacing.L40};
  height: ${({ theme }) => theme.spacing.L40};
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const ItemTitleWrapper = styled.div`
  ${ItemStyles};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: 0 0 ${({ theme }) => theme.spacing.M16} 0;
`;

const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemTitleButton = styled(LinkButton)`
  display: flex;
  ${({ theme }) => theme.fontSize.M16};
`;

const ItemTitleText = styled.span`
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.div`
  display: inline-flex;
`;

const ItemExtraLabel = styled.span`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY};
  padding-left: ${({ theme }) => theme.spacing.S4};
`;

const IconItem = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
`;

const ItemLabel = styled.span`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  padding-left: ${({ theme }) => theme.spacing.S8};
`;

export {
  Item,
  SubList,
  Close,
  ItemTitle,
  ItemTitleWrapper,
  ItemTitleText,
  ItemTitleButton,
  ItemLink,
  ItemLabel,
  ItemExtraLabel,
  ItemToggle,
  IconItem,
  Link,
  Badge,
  LinkWrapper,
};
