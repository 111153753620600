import styled, { css } from 'styled-components';

import type { HeaderVariant } from 'components/Layouts/Headers/StandardHeader';

const Wrapper = styled.div`
  display: none;
  align-content: start;
  margin-right: auto;
  margin-left: ${({ theme }) => theme.spacing.M32};
  gap: ${({ theme }) => theme.spacing.M16};
  @media (min-width: 1100px) {
    display: flex;
  }
`;

const MainButtonText = styled.span`
  position: relative;
`;

export const getNavigationMainStyles = css<{
  variant: HeaderVariant;
}>`
  color: ${({ theme, variant }) =>
    variant === 'TRANSPARENT' ? theme.colors.WHITE : theme.colors.GREY_DARKER};
  width: 100%;
  padding: ${({ theme }) => theme.spacing.S8};
  margin: 0;
  ${({ theme }) => theme.fontSize.M16}
  cursor: pointer;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  :active {
    color: ${({ theme, variant }) =>
      variant === 'TRANSPARENT'
        ? theme.colors.GREY_LIGHT
        : theme.colors.GREY_DARK};
  }
`;

export const getNavigationMainButtonOpen = css<{
  variant: HeaderVariant;
}>`
  content: '';
  background-color: ${({ theme, variant }) =>
    variant === 'TRANSPARENT' ? theme.colors.WHITE : theme.colors.GREY_DARKER};
  position: absolute;
  left: 0px;
  display: block;
  height: 2px;
  width: 100%;
  bottom: -10px;
`;

const MainButton = styled.button<{
  isOpen: boolean;
  variant: HeaderVariant;
}>`
  ${getNavigationMainStyles}
  ${({ isOpen }) =>
    isOpen &&
    css`
      ${MainButtonText} {
        ::after {
          ${getNavigationMainButtonOpen}
        }
      }
    `};
`;

const MainLink = styled.a<{
  isOpen: boolean;
  variant: HeaderVariant;
}>`
  ${getNavigationMainStyles};
  display: block;
  text-transform: none;
  :hover {
    ${MainButtonText} {
      ::after {
        ${getNavigationMainButtonOpen}
      }
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      ${MainButtonText} {
        ::after {
          ${getNavigationMainButtonOpen}
        }
      }
    `};
`;

export { MainButton, MainButtonText, MainLink, Wrapper };
