import type { SectionView } from 'api/types/adviewApiTypes';

export enum VERTICAL {
  FARMING = 'farming',
  MARKETPLACE = 'marketplace',
  MOTOR = 'motor',
}

export type Vertical = 'motor' | 'marketplace' | 'farming';

export function getVerticalFromBreadcrumbs(
  breadcrumbs?: SectionView[],
): Vertical {
  const breadcrumb =
    breadcrumbs &&
    breadcrumbs.find(({ name }) => name === 'motor' || name === 'farming');
  if (breadcrumb && breadcrumb.name) {
    return breadcrumb.name as Vertical;
  } else {
    return 'marketplace';
  }
}
