import { fireToast } from 'helpers/Toasts';

type TFooterLinks = {
  display: string;
  href?: string;
  external?: boolean;
  isLink?: boolean;
  isHeading?: boolean;
  onClick?: () => void;
};

function openConsentModal() {
  if (typeof window.Didomi !== 'undefined') {
    window.Didomi.preferences.show();
  } else {
    fireToast({
      type: 'ERROR',
      text: 'Oops! Something went wrong, please try again later.',
    });
  }
}

const mobileLinks: (t: string, u?: string) => TFooterLinks[] = (
  dataRequestsUrl: string,
  baseUrl?: string,
) => [
  {
    display: 'About Us',
    href: 'https://hello.donedeal.ie/hc/en-us/categories/200189622-About-DoneDeal',
    isLink: true,
    external: true,
  },
  {
    display: 'Press',
    href: 'https://hello.donedeal.ie/hc/en-us/articles/201251891-Media-Press-Enquiries',
    isLink: true,
    external: true,
  },
  {
    display: 'Blog',
    href: 'https://blog.donedeal.ie/',
    isLink: true,
    external: true,
  },
  {
    display: 'Help',
    href: 'https://hello.donedeal.ie/hc/en-us/',
    isLink: true,
    external: true,
  },
  {
    display: 'Business Enquiries',
    href: 'https://www.sales.donedeal.ie/',
    isLink: true,
    external: true,
  },
  {
    display: 'Sell My Car',
    href: `${baseUrl}/sell-my-car`,
    isLink: true,
    external: false,
  },
  {
    display: 'History Check',
    href: `${baseUrl}/greenlight-history-check`,
    isLink: true,
    external: false,
  },
  {
    display: 'Safety',
    href: 'https://hello.donedeal.ie/hc/en-us/categories/200184891-Safety',
    isLink: true,
    external: true,
  },
  {
    display: 'Data Requests',
    href: dataRequestsUrl,
    isLink: true,
    external: true,
  },
  {
    display: 'Consent Choices',
    isLink: false,
    onClick: openConsentModal,
  },
];

const exploreLinks: (t?: string) => TFooterLinks[] = (baseUrl?: string) => [
  { display: 'Explore', isHeading: true },
  {
    display: 'Cars for sale',
    isLink: true,
    href: `${baseUrl}/cars`,
    external: false,
  },
  {
    display: 'Motorbikes for sale',
    isLink: true,
    href: `${baseUrl}/motorbikes`,
    external: false,
  },
  {
    display: 'All Sections',
    isLink: true,
    href: `${baseUrl}/all`,
    external: false,
  },
  {
    display: 'Sell My Car',
    isLink: true,
    href: `${baseUrl}/sell-my-car`,
    external: false,
  },
  {
    display: 'Vehicle History Check',
    isLink: true,
    href: `${baseUrl}/greenlight-history-check`,
    external: false,
  },
  { display: 'Sitemap', isLink: true, href: '/sitemap', external: false },
  {
    display: 'Blog',
    isLink: true,
    href: 'https://blog.donedeal.ie/',
    external: true,
  },
];

const ourFriendsLinks: TFooterLinks[] = [
  { display: 'Our Friends', isHeading: true },
  {
    display: 'Daft.ie',
    isLink: true,
    href: 'https://www.daft.ie/',
    external: true,
  },
  {
    display: 'Adverts.ie',
    isLink: true,
    href: 'https://www.adverts.ie/',
    external: true,
  },
];

const customerHappinessLinks: (t: string) => TFooterLinks[] = (
  dataRequestsUrl: string,
) => [
  { display: 'Customer Happiness', isHeading: true },
  {
    display: 'Help',
    isLink: true,
    href: 'https://hello.donedeal.ie/hc/en-us/',
    external: true,
  },
  {
    display: 'Buying',
    isLink: true,
    href: 'https://hello.donedeal.ie/hc/en-us/categories/200620172-Buying',
    external: true,
  },
  {
    display: 'Selling',
    isLink: true,
    href: 'https://hello.donedeal.ie/hc/en-us/categories/200599651-Selling',
    external: true,
  },
  {
    display: 'Stay Safe',
    isLink: true,
    href: 'https://hello.donedeal.ie/hc/en-us/categories/200184891-Safety',
    external: true,
  },
  {
    display: 'Contact Us',
    isLink: true,
    href: 'https://hello.donedeal.ie/hc/en-us/requests/new',
    external: true,
  },
  {
    display: 'Data Requests',
    isLink: true,
    href: dataRequestsUrl,
    external: true,
  },
  { display: 'Consent Choices', isLink: false, onClick: openConsentModal },
];

const aboutUsLinks: TFooterLinks[] = [
  { display: 'About Us', isHeading: true },
  {
    display: 'Our Story',
    isLink: true,
    href: 'https://hello.donedeal.ie/hc/en-us/articles/201385402-Our-Story',
    external: true,
  },
  {
    display: 'Meet the Team',
    isLink: true,
    href: 'https://hello.donedeal.ie/hc/en-us/sections/200382662-DoneDeal-Team',
    external: true,
  },
  {
    display: 'Media & Press Enquiries',
    isLink: true,
    href: 'https://hello.donedeal.ie/hc/en-us/articles/201251891-Media-Press-Enquiries',
    external: true,
  },
];

const businessLinks: TFooterLinks[] = [
  { display: 'Business', isHeading: true },
  {
    display: 'Car & Dealer Enquiries',
    isLink: true,
    href: 'https://www.sales.donedeal.ie/motors',
    external: true,
  },
  {
    display: 'Banner Advertisement',
    isLink: true,
    href: 'https://www.sales.donedeal.ie/ad-sizes',
    external: true,
  },
  {
    display: 'Machinery Dealer Advertising',
    isLink: true,
    href: 'https://www.sales.donedeal.ie/farming',
    external: true,
  },
];

export {
  mobileLinks,
  exploreLinks,
  ourFriendsLinks,
  customerHappinessLinks,
  aboutUsLinks,
  businessLinks,
};
