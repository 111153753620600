import React from 'react';
import { ChevronForwardOutlineIc } from '@dsch/dd-icons';
import * as NavigationItemStyles from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/NavigationItem/NavigationItem.styled';
import { useNavigationItem } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/NavigationItem/NavigationItem.hook';
import { NavigationItemProps } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/NavigationItem/NavigationItem.typed';

const NavigationItem = ({
  option,
  renderItem,
  subMenuWidth,
  className,
  isFirstColumn,
  isPrimary,
}: {
  option: NavigationItemProps;
  renderItem?: (item: NavigationItemProps) => React.ReactNode;
  subMenuWidth?: number;
  className?: string;
  isFirstColumn?: boolean;
  isPrimary?: boolean;
}): React.ReactElement => {
  const { handleClick, hasSubmenu, items, submenuRef, submenuIsOpen } =
    useNavigationItem(option);

  const openSubMenu = hasSubmenu && items && subMenuWidth && subMenuWidth > 0;

  const getItems = () => {
    if (!items) return null;
    const lengthFirstColumn = Math.ceil(items.length / 2);
    return (
      items &&
      items.map((item, index) => {
        const isLastItem = items?.length - 1 === index;

        return (
          <NavigationItem
            key={index}
            option={item}
            renderItem={renderItem}
            isFirstColumn={index <= lengthFirstColumn - 1}
            isPrimary={Boolean(isLastItem)}
          />
        );
      })
    );
  };

  const getItemLink = () => {
    if (isPrimary) {
      return (
        <NavigationItemStyles.ItemLastLink
          href={option.link}
          fontSize={'M16'}
          data-tracking="header-nav"
        >
          {option.label}
        </NavigationItemStyles.ItemLastLink>
      );
    } else {
      return (
        <NavigationItemStyles.ItemLink
          href={option.link}
          target={'_self'}
          data-tracking="header-nav"
        >
          {option.label}
          {!renderItem && option.extraLabel && (
            <NavigationItemStyles.ItemExtraLabel>
              {option.extraLabel}
            </NavigationItemStyles.ItemExtraLabel>
          )}
        </NavigationItemStyles.ItemLink>
      );
    }
  };

  return (
    <NavigationItemStyles.Item
      onMouseDown={handleClick}
      className={className}
      isFirstColumn={isFirstColumn}
    >
      {option.link ? (
        getItemLink()
      ) : (
        <>
          <NavigationItemStyles.ItemLabel>
            <>
              {!renderItem && option.label}
              {!renderItem && option.extraLabel && (
                <NavigationItemStyles.ItemExtraLabel>
                  {option.extraLabel}
                </NavigationItemStyles.ItemExtraLabel>
              )}
            </>
            {hasSubmenu && <ChevronForwardOutlineIc width={16} height={16} />}
          </NavigationItemStyles.ItemLabel>

          {submenuIsOpen && (
            <NavigationItemStyles.ItemLabelFake>
              <div>
                {!renderItem && option.label}
                {!renderItem && option.extraLabel && (
                  <NavigationItemStyles.ItemExtraLabel>
                    {option.extraLabel}
                  </NavigationItemStyles.ItemExtraLabel>
                )}
              </div>
              {hasSubmenu && <ChevronForwardOutlineIc width={16} height={16} />}
            </NavigationItemStyles.ItemLabelFake>
          )}
        </>
      )}

      {openSubMenu && (
        <NavigationItemStyles.SubList
          ref={submenuRef}
          windowWidth={subMenuWidth}
        >
          {getItems()}
        </NavigationItemStyles.SubList>
      )}
      {renderItem && renderItem(option)}
    </NavigationItemStyles.Item>
  );
};

export { NavigationItem };
