import React from 'react';
import { ChevronForwardOutlineIc } from '@dsch/dd-icons';
import * as NavigationItemStyles from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/components/NavigationItem/NavigationItem.styled';
import { NavigationItemProps } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/NavigationItem/NavigationItem.typed';

const NavigationItem = ({
  option,
  onClick,
}: {
  option: NavigationItemProps;
  onClick: (isOpen: boolean) => void;
}): React.ReactElement => {
  const items = option.items;
  const hasSubmenu = !!items;
  return (
    <>
      <NavigationItemStyles.Item
        onClick={() => {
          hasSubmenu && onClick(true);
        }}
      >
        {option.link ? (
          <NavigationItemStyles.ItemLink
            href={option.link}
            target={'_self'}
            data-tracking="header-nav"
          >
            {option.label}
            {option.extraLabel && option.extraLabel && (
              <NavigationItemStyles.ItemExtraLabel>
                {option.extraLabel}
              </NavigationItemStyles.ItemExtraLabel>
            )}
          </NavigationItemStyles.ItemLink>
        ) : (
          <NavigationItemStyles.ItemLabel>
            <div>
              {option.label && option.label}
              {option.extraLabel && option.extraLabel && (
                <NavigationItemStyles.ItemExtraLabel>
                  {option.extraLabel}
                </NavigationItemStyles.ItemExtraLabel>
              )}
            </div>
            {hasSubmenu && <ChevronForwardOutlineIc width={16} height={16} />}
          </NavigationItemStyles.ItemLabel>
        )}
      </NavigationItemStyles.Item>
    </>
  );
};

export { NavigationItem };
