import { useCallback, useMemo, useRef, useState } from 'react';
import { useClickOutside } from 'hooks/useClickOutside';

const useNavigation = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [navigationIsOpen, setNavigationOpen] = useState(false);

  const toggleNavigation = useCallback(
    () => setNavigationOpen((state) => !state),
    [],
  );
  const closeNavigation = useCallback(() => setNavigationOpen(false), []);

  const childrenProps = useMemo(() => {
    return {
      isOpen: navigationIsOpen,
      setNavigationOpen: setNavigationOpen,
    };
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationIsOpen, toggleNavigation]);

  useClickOutside({
    active: navigationIsOpen,
    ref: containerRef,
    onClickOutside: () => setNavigationOpen(false),
  });

  const rootMenuRef = useRef<HTMLUListElement>(null);

  return {
    childrenProps,
    navigationIsOpen,
    rootMenuRef,
    containerRef,
    closeNavigation,
  };
};

export { useNavigation };
