import type { AxiosResponse, CancelTokenSource } from 'axios';
import type { IUnreadInAppMessageResponse } from 'api/types/inAppMessageApiTypes';

import { donedealGateway } from 'api/api-utils/commonApiUtils';

async function getUnreadInAppMessageData(
  cancelTokenSource: CancelTokenSource,
): Promise<AxiosResponse<IUnreadInAppMessageResponse>> {
  const response: AxiosResponse<IUnreadInAppMessageResponse> =
    await donedealGateway.get('/ddapi/v1/notifications/iam/unread', {
      cancelToken: cancelTokenSource.token,
    });
  return response;
}

export const inAppMessagingApi = { getUnreadInAppMessageData };
