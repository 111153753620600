import React, { useEffect, useState } from 'react';
import { NavigationItem } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/components/NavigationItem/NavigationItem';
import * as NavigationStyles from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/components/Navigation/Navigation.styled';
import * as NavigationItemStyles from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/components/NavigationItem/NavigationItem.styled';
import { CloseOutlineIc, ChevronBackOutlineIc } from '@dsch/dd-icons';
import { NavigationProps } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/components/Navigation/Navigation.typed';
import { Accordion } from 'components/ToolkitV2/Accordion/Accordion';
import { NavigationItemProps } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/NavigationItem/NavigationItem.typed';
import { useAccordion } from 'components/ToolkitV2/Accordion/useAccordion';

export const Navigation = (props: NavigationProps): React.ReactElement => {
  const { label, items, onClose } = props;
  const { checkIsOpen, onChange, setOpenIds } = useAccordion({});
  const [submenuIsOpen, setSubmenuIsOpen] = useState<null | number>(null);
  const submenuItems = submenuIsOpen && items[submenuIsOpen].items;

  useEffect(() => {
    setOpenIds([`navigation`]);
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLinkComponent = (item: NavigationItemProps, isLast: boolean) => {
    if (isLast) {
      return (
        <NavigationItemStyles.ItemLastLink
          href={item.link}
          fontSize={'M16'}
          data-tracking="header-nav"
        >
          {item.label}
        </NavigationItemStyles.ItemLastLink>
      );
    } else {
      return (
        <NavigationItemStyles.ItemLink
          href={item.link}
          target={'_self'}
          data-tracking="header-nav"
        >
          {item.label}
        </NavigationItemStyles.ItemLink>
      );
    }
  };

  return (
    <>
      <NavigationStyles.Wrapper>
        <Accordion
          header={label}
          content={
            <NavigationStyles.ListWrapper>
              <NavigationStyles.List>
                {items.map((item, index) => (
                  <NavigationItem
                    key={index}
                    option={item}
                    onClick={() => setSubmenuIsOpen(index)}
                  />
                ))}
              </NavigationStyles.List>
            </NavigationStyles.ListWrapper>
          }
          isOpen={checkIsOpen('navigation')}
          onChange={() => onChange(`navigation`)}
        />
      </NavigationStyles.Wrapper>
      {submenuIsOpen && items[submenuIsOpen] && (
        <NavigationItemStyles.SubList>
          <NavigationItemStyles.ItemBack
            onClick={() => setSubmenuIsOpen(null)}
            className={'back-button'}
          >
            <span>
              <ChevronBackOutlineIc width="20" height="20" />
            </span>
            Back to {items[submenuIsOpen].label.toLowerCase()}
          </NavigationItemStyles.ItemBack>

          <NavigationStyles.Close onClick={onClose} data-testid="modal-close">
            <span>
              <CloseOutlineIc width="16" height="16" />
            </span>
          </NavigationStyles.Close>

          {submenuItems &&
            submenuItems.length > 0 &&
            submenuItems.map((item, index) => (
              <NavigationItemStyles.Item key={index}>
                {item.link ? (
                  getLinkComponent(
                    item,
                    Boolean(submenuItems.length - 1 === index),
                  )
                ) : (
                  <NavigationItemStyles.ItemLabel>
                    <div>
                      {item.label && item.label}
                      {item.extraLabel && item.extraLabel && (
                        <NavigationItemStyles.ItemExtraLabel>
                          {item.extraLabel}
                        </NavigationItemStyles.ItemExtraLabel>
                      )}
                    </div>
                  </NavigationItemStyles.ItemLabel>
                )}
              </NavigationItemStyles.Item>
            ))}
        </NavigationItemStyles.SubList>
      )}
    </>
  );
};
