import styled, { css } from 'styled-components';
import {
  getNavigationMainButtonOpen,
  getNavigationMainStyles,
} from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/HeaderNavigation.styled';
import { BaseBadge } from 'components/Toolkit/BaseBadge/BaseBadge';
import { HeaderVariant } from 'components/Layouts/Headers/StandardHeader';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.button<{
  isOpen: boolean;
  variant: HeaderVariant;
}>`
  ${getNavigationMainStyles}
  ${({ isOpen }) =>
    isOpen &&
    css`
      ::after {
        ${getNavigationMainButtonOpen};
        bottom: 0;
      }
    `};
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
`;

const Profile = styled.div`
  position: relative;
`;

const Name = styled.span<{ variant: HeaderVariant }>`
  display: none;

  @media (min-width: 840px) {
    display: initial;
    ${({ theme }) => theme.fontSize.M16};
    margin: ${({ theme }) => `0 ${theme.spacing.S4}`};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme, variant }) =>
      variant === 'TRANSPARENT'
        ? theme.colors.WHITE
        : theme.colors.GREY_DARKER};
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const Badge = styled(BaseBadge)`
  position: absolute;
  top: 1px;
  right: 1px;
  width: 8px;
  height: 8px;
`;

export { Profile, Wrapper, Badge, Container, Name };
