import styled, { css } from 'styled-components';
import { Link } from 'components/Toolkit/Button/Link';

const ItemStyles = css`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => theme.spacing.S8};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const SubList = styled.ul<{ windowWidth: number }>`
  z-index: 10;
  box-shadow: 2px 1px 4px 0px rgba(51, 51, 51, 0.24);
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  position: absolute;
  display: none;
  opacity: 0;
  left: 100%;
  top: 0;
  margin: ${({ theme }) => theme.spacing.S8} 0px 0px 0px;
  padding: ${({ theme }) => theme.spacing.S8};
  border-left: 1px solid ${({ theme }) => theme.colors.GREY_LIGHT};
  border-radius: 0 ${({ theme }) => theme.spacing.S4}
    ${({ theme }) => theme.spacing.S4} 0;
  height: calc(100% - ${({ theme }) => theme.spacing.S8});
  margin-left: -2px;
  grid-auto-flow: column;

  width: calc(${({ windowWidth }) => windowWidth}px - 304px);
  @media (max-width: 1210px) {
    width: calc(${({ windowWidth }) => windowWidth}px - 286px);
  }
`;

const ItemLabel = styled.button`
  ${ItemStyles};
  position: relative;
`;

const ItemLabelFake = styled.span`
  ${ItemStyles};
  position: absolute;
  left: ${({ theme }) => theme.spacing.M16};
  width: calc(100% - ${({ theme }) => theme.spacing.M32});
  background: ${({ theme }) => theme.colors.GREY_LIGHTER} !important;
  :hover {
    background: ${({ theme }) => theme.colors.GREY_LIGHT} !important;
  }
`;

const Item = styled.li<{ isFirstColumn?: boolean }>`
  cursor: pointer;
  word-break: break-word;
  width: 100%;
  align-items: center;
  display: flex;
  white-space: nowrap;
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => theme.spacing.S4}
    ${({ theme }) => theme.spacing.M16};

  ${SubList} {
    opacity: 1;
    > li {
      padding: 0 ${({ theme }) => theme.spacing.M16};
    }
  }

  :hover {
    > ${ItemLabelFake} {
      border-radius: ${({ theme }) => theme.borderRadius.default};
      background-color: ${({ theme }) => theme.colors.GREY_LIGHT};
    }
    > ${SubList} {
      display: grid;
      grid-template-columns: auto auto;
      grid-column-gap: 50px;
      overflow-y: scroll;
      padding: ${({ theme }) => theme.spacing.M16} 0px;
      column-gap: ${({ theme }) => theme.spacing.S8};
      grid-auto-rows: max-content;
    }
  }

  ${({ isFirstColumn }) => {
    if (isFirstColumn) return 'grid-column: 1';
    return 'grid-column: 2';
  }}
`;

const ItemLink = styled.a`
  ${ItemStyles};
  justify-content: flex-start;
  :hover {
    border-radius: ${({ theme }) => theme.borderRadius.default};
    background-color: ${({ theme }) => theme.colors.GREY_LIGHT};
  }
`;

const ItemLastLink = styled(Link)`
  padding: ${({ theme }) => theme.spacing.S8};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const ItemExtraLabel = styled.span`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY};
  padding-left: ${({ theme }) => theme.spacing.S4};
`;

export {
  Item,
  SubList,
  ItemLabel,
  ItemLink,
  ItemExtraLabel,
  ItemLabelFake,
  ItemLastLink,
};
