import { useState, useEffect, createRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useUserContext } from 'contexts/UserContext';
import { MenuIc, SearchIc } from '@dsch/dd-icons';

import { media } from 'helpers/breakpoints';

import { Button } from 'components/Toolkit/Button/Button';
import { ButtonLink } from 'components/Toolkit/Button/ButtonLink';
import { LinkButton } from 'components/Toolkit/Button/LinkButton';
import { useGlobalContext } from 'contexts/GlobalContext';
import { VerifyTooltip } from 'components/PageWrappers/SharedComponents/VerifyTooltip/VerifyTooltip';

import {
  BaseHeader,
  Right,
} from 'components/Layouts/Headers/Shared/BaseHeader';
import { HeaderUserProfile } from 'components/Layouts/Headers/HeaderComponents/HeaderUserProfile';
import { HeaderDropdown } from 'components/Layouts/Headers/HeaderComponents/HeaderDropdown';
import { useAuthOptions } from 'hooks/useAuthOptions';
import { DISPLAY_NAVIGATION_MENU } from 'utils';
import { HeaderNavigation } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/HeaderNavigation';
import { HeaderNavigationMobile } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/HeaderNavigationMobile';
import { BaseBadge } from 'components/Toolkit/BaseBadge/BaseBadge';
import { Navigation } from 'domains/Navigation';
import { HeaderUserDropdown } from 'components/Layouts/Headers/HeaderComponents/HeaderUserDropdown/HeaderUserDropdown';
import { HeaderUserDropdownProfile } from 'components/Layouts/Headers/HeaderComponents/HeaderUserDropdownProfile/HeaderUserDropdownProfile';
import {
  getNavigationMainButtonOpen,
  getNavigationMainStyles,
} from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/HeaderNavigation.styled';

export type HeaderVariant = 'DEFAULT' | 'TRANSPARENT';

export interface StandardHeaderProps {
  /** Current logged in user's name */
  username?: string;
  /** Current base url of application */
  baseUrl: string;
  /** Cdn URl */
  cdnUrl: string;
  /** Count of notifications for user */
  notificationCount?: number;
  /** Count of unread messages for user */
  unreadMessageCount?: number;
  /** Count of unread history checks for user */
  unreadHistoryCheckCount?: number;
  /** Header variants */
  variant?: HeaderVariant;
  /** Has spacer to push content lower */
  headerHasSpacer?: boolean;
  vertical?: string;
  navigation: Navigation[];
}

const Wrapper = styled.div<{
  variant: HeaderVariant;
  hasSpacer?: boolean;
}>`
  position: relative;
  z-index: ${({ theme }) => theme.zIndices.HEADER};
  margin-bottom: ${({ hasSpacer, theme }) =>
    hasSpacer ? theme.spacing.M24 : 0};
  background: ${({ theme, variant }) =>
    variant === 'TRANSPARENT' ? 'transparent' : theme.colors.WHITE};
  ${({ variant }) => variant === 'TRANSPARENT' && 'margin-bottom: -72px;'};
  ${({ variant, theme }) =>
    variant === 'DEFAULT' &&
    `border-bottom: 1px solid ${theme.colors.GREY_LIGHT}`};

  .desktop-only {
    display: none;
    @media (min-width: 840px) {
      display: initial;
    }
  }

  ${Right} {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    flex: auto;
  }
`;

const PlaceAdButton = styled.div`
  width: 80px;
  @media (min-width: 840px) {
    width: 140px;
  }
`;

const SearchWrapper = styled.button<{ variant: HeaderVariant }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover,
  &:active {
    box-shadow: inset 0 -1px 0 0 ${({ theme, variant }) => (variant === 'TRANSPARENT' ? theme.colors.WHITE : theme.colors.GREY_DARKER)};
  }
`;

const PlaceAddButtonCss = css`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.WHITE};
  border-color: ${({ theme }) => theme.colors.WHITE};
  border-width: ${({ theme }) => theme.spacing.S2};
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    background: rgba(255, 255, 255, 0.32);
    color: ${({ theme }) => theme.colors.WHITE};
    border-color: ${({ theme }) => theme.colors.WHITE};
    border-width: ${({ theme }) => theme.spacing.S2};
  }
  :active {
    border-width: ${({ theme }) => theme.spacing.S2};
    color: ${({ theme }) => theme.colors.GREY_LIGHT};
    border-color: ${({ theme }) => theme.colors.GREY_LIGHT};
  }
`;

const SearchIcon = styled(SearchIc)`
  ${media.large} {
    margin-right: ${({ theme }) => theme.spacing.S4};
  }
`;

const SearchText = styled.p<{ variant: HeaderVariant }>`
  display: none;
  ${media.large} {
    display: block;
    ${({ theme }) => theme.fontSize.M14};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    color: ${({ theme, variant }) =>
      variant === 'TRANSPARENT'
        ? theme.colors.WHITE
        : theme.colors.GREY_DARKER};
  }
`;

const UserDropdownWrapper = styled.div`
  display: none;
  @media (min-width: 1100px) {
    display: flex;
  }
`;

const NavigationBurgerWrapper = styled.div`
  display: flex;
  position: relative;
  @media (min-width: 1100px) {
    display: none;
  }
`;

const SHeaderUserProfile = styled(HeaderUserProfile)<{
  isNotLoggedIn: boolean;
}>`
  margin-left: ${({ theme, isNotLoggedIn }) =>
    isNotLoggedIn ? theme.spacing.M16 : theme.spacing.S8};
`;

const SIoMenu = styled(MenuIc)`
  margin-right: ${({ theme }) => theme.spacing.S8};
`;

const StyledBadge = styled(BaseBadge)`
  position: absolute;
  top: 1px;
  right: 14px;
  width: 8px;
  height: 8px;
  background-color: ${({ theme }) => theme.colors.RED};
`;

const PlaceAdButtonWrapper = styled(Button)`
  ${PlaceAddButtonCss};
`;
const PlaceAdButtonLinkWrapper = styled(ButtonLink)`
  ${PlaceAddButtonCss};
`;

const SignUpOrLoginButton = styled.button`
  position: relative;
  ${getNavigationMainStyles};
  :hover {
    ::after {
      ${getNavigationMainButtonOpen};
      bottom: 1px;
    }
  }
`;

function StandardHeader({
  username = '',
  notificationCount,
  baseUrl,
  cdnUrl,
  unreadMessageCount,
  unreadHistoryCheckCount,
  variant = 'DEFAULT',
  headerHasSpacer = false,
  vertical = '',
  navigation,
}: StandardHeaderProps) {
  const theme = useTheme();
  const { user } = useUserContext();
  const [posX, setPosX] = useState<number>(0);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuBurgerOpen, setMenuBurgerOpen] = useState(false);
  const headerDropDownRef = createRef<HTMLDivElement>();
  const headerUserProfileRef = createRef<HTMLButtonElement>();
  const [baseHeaderWrapperWidth, setBaseHeaderWrapperWidth] =
    useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      const element = document.getElementById('base-header-wrapper');
      if (element) {
        const newWidth = element.clientWidth;
        setBaseHeaderWrapperWidth(newWidth);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [vertical]);

  const { handleLogin, handleLogout, isAuthenticated } = useAuthOptions();

  const { setIsGlobalSearchModalOpen } = useGlobalContext();

  function handleClick(event: React.MouseEvent) {
    event.stopPropagation();
    setIsActive(!isActive);
  }

  function handleActive() {
    setIsOpen(!isOpen);
  }

  function handlePosX(xCoordinate: number) {
    let fullWidth = window.innerWidth;
    setPosX(fullWidth - xCoordinate);
  }

  const handleLoginCallback = () => handleLogin();

  const handleLogoutCallback = () => {
    setMenuBurgerOpen(false);
    return handleLogout();
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickOutside);
    };
    // TODO: Clean up this effect's dependencies. We're disabling this lint error for now so we can
    // clean up the lint logs. Ideally we should rewrite this code to be less error prone and trust
    // the lint rule's judgement.
    // https://distilledsch.tpondemand.com/RestUI/Board.aspx#page=userstory/98606
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function handleClickOutside(event: MouseEvent | KeyboardEvent) {
    if (
      (headerDropDownRef.current &&
        headerDropDownRef.current.contains(event.target as Node)) ||
      (headerUserProfileRef.current &&
        headerUserProfileRef.current.contains(event.target as Node))
    ) {
      return;
    }
    setIsActive(false);
    setIsOpen(false);
  }

  const displayNavigationMenu =
    DISPLAY_NAVIGATION_MENU && DISPLAY_NAVIGATION_MENU === 'true';

  const placeAdButtonLink =
    variant === 'TRANSPARENT' && displayNavigationMenu ? (
      <PlaceAdButtonLinkWrapper
        ofType="SECONDARY"
        href={`${baseUrl}/publish`}
        data-tracking="menu-bar-place-ad-button"
      >
        Place Ad
      </PlaceAdButtonLinkWrapper>
    ) : (
      <ButtonLink
        ofType="SECONDARY"
        href={`${baseUrl}/publish`}
        data-tracking="menu-bar-place-ad-button"
      >
        Place Ad
      </ButtonLink>
    );

  const placeAdButton =
    variant === 'TRANSPARENT' && displayNavigationMenu ? (
      <PlaceAdButtonWrapper
        ofType="SECONDARY"
        data-tracking="menu-bar-place-ad-button"
      >
        Place Ad
      </PlaceAdButtonWrapper>
    ) : (
      <Button ofType="SECONDARY" data-tracking="menu-bar-place-ad-button">
        Place Ad
      </Button>
    );

  return (
    <Wrapper variant={variant} hasSpacer={headerHasSpacer}>
      <div className="row">
        <div className="columns small-12">
          <BaseHeader
            baseUrl={baseUrl}
            vertical={vertical}
            cdnUrl={cdnUrl}
            variant={variant}
          >
            {displayNavigationMenu && (
              <HeaderNavigation
                variant={variant}
                subMenuWidth={baseHeaderWrapperWidth}
                navigation={navigation}
              />
            )}
            <SearchWrapper
              onClick={() => setIsGlobalSearchModalOpen(true)}
              variant={variant}
            >
              <SearchIcon
                data-testid="global-search-icon"
                color={
                  variant === 'TRANSPARENT'
                    ? theme.colors.WHITE
                    : theme.colors.GREY_DARKER
                }
                width={20}
                height={20}
              />
              {!displayNavigationMenu && (
                <SearchText variant={variant}>Search</SearchText>
              )}
            </SearchWrapper>
            <PlaceAdButton>
              {user && !user?.isMinimumVerified ? (
                <VerifyTooltip
                  arrowPosition={['right', 'right', 'center']}
                  tooltipPosition={'below'}
                >
                  {placeAdButton}
                </VerifyTooltip>
              ) : (
                placeAdButtonLink
              )}
            </PlaceAdButton>

            {displayNavigationMenu ? (
              <>
                <UserDropdownWrapper>
                  {isAuthenticated ? (
                    <div
                      onMouseLeave={() => setIsActive(false)}
                      onMouseOver={() => setIsActive(true)}
                    >
                      <HeaderUserDropdownProfile
                        ref={headerUserProfileRef}
                        username={username}
                        notificationCount={notificationCount}
                        posX={handlePosX}
                        isActive={isActive}
                        handleActive={handleActive}
                        variant={variant}
                      />
                      <HeaderUserDropdown
                        ref={headerDropDownRef}
                        isOpen={isOpen}
                        unreadMessageCount={unreadMessageCount}
                        unreadHistoryCheckCount={unreadHistoryCheckCount}
                        posX={posX}
                        setIsOpen={setIsOpen}
                        logout={handleLogoutCallback}
                      />
                    </div>
                  ) : (
                    <SignUpOrLoginButton
                      className="desktop-only"
                      onClick={handleLoginCallback}
                      data-testid="desktop-login"
                      data-tracking="menu-bar-login"
                      variant={variant}
                    >
                      Sign up or Login
                    </SignUpOrLoginButton>
                  )}
                </UserDropdownWrapper>
                <NavigationBurgerWrapper>
                  <button
                    onClick={() => setMenuBurgerOpen(true)}
                    data-testid="header-burger-btn"
                  >
                    {notificationCount && notificationCount > 0 ? (
                      <StyledBadge />
                    ) : null}
                    <SIoMenu
                      color={
                        variant === 'TRANSPARENT'
                          ? theme.colors.WHITE
                          : theme.colors.GREY_DARKER
                      }
                    />
                  </button>
                  <HeaderNavigationMobile
                    username={username}
                    unreadMessageCount={unreadMessageCount}
                    unreadHistoryCheckCount={unreadHistoryCheckCount}
                    askToClose={() => setMenuBurgerOpen(false)}
                    isOpen={isMenuBurgerOpen}
                    onLogin={handleLogin}
                    logout={handleLogoutCallback}
                    navigation={navigation}
                  />
                </NavigationBurgerWrapper>
              </>
            ) : (
              <>
                {!isAuthenticated && (
                  <LinkButton
                    ofType="TERTIARY"
                    className="desktop-only"
                    onClick={handleLoginCallback}
                    data-testid="desktop-login"
                    data-tracking="menu-bar-login"
                    colorVariant={
                      variant === 'TRANSPARENT' ? 'WHITE' : undefined
                    }
                  >
                    Log in
                  </LinkButton>
                )}
                <SHeaderUserProfile
                  ref={headerUserProfileRef}
                  username={username}
                  profileImage={`${cdnUrl}/images/illustrations/profile.svg`}
                  notificationCount={notificationCount}
                  posX={handlePosX}
                  handleClick={handleClick}
                  isActive={isActive}
                  handleActive={handleActive}
                  isNotLoggedIn={!isAuthenticated}
                  variant={variant}
                />
                <HeaderDropdown
                  ref={headerDropDownRef}
                  username={username}
                  isOpen={isOpen}
                  cdnUrl={cdnUrl}
                  unreadMessageCount={unreadMessageCount}
                  unreadHistoryCheckCount={unreadHistoryCheckCount}
                  posX={posX}
                  setIsOpen={setIsOpen}
                  onLogin={handleLogin}
                  logout={handleLogout}
                />
              </>
            )}
          </BaseHeader>
        </div>
      </div>
    </Wrapper>
  );
}

export { StandardHeader };
