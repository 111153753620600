import styled from 'styled-components';
import { NavigationAnimationY } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigationMobile/components/NavigationItem/NavigationItem.styled';

const Close = styled.div`
  z-index: 999999;
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  cursor: pointer;
  top: ${({ theme }) => theme.spacing.M24};
  right: ${({ theme }) => theme.spacing.M24};
  position: absolute;
  width: ${({ theme }) => theme.spacing.L40};
  height: ${({ theme }) => theme.spacing.L40};
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ListWrapper = styled.div`
  position: relative;
  top: 100%;
  left: 0;
  margin: 0;
  z-index: 10;
  animation: 150ms cubic-bezier(0, 0.76, 0.47, 1) 0s 1 normal
    ${NavigationAnimationY};
`;

const List = styled.ul`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.WHITE};
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export { Wrapper, ListWrapper, List, Close };
