import { Navigation } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/components/Navigation/Navigation';
import { HeaderNavigationProps } from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/HeaderNavigation.typed';
import * as NavigationStyled from 'components/Layouts/Headers/HeaderComponents/HeaderNavigation/HeaderNavigation.styled';

const HeaderNavigation = (props: HeaderNavigationProps) => {
  const { variant, subMenuWidth, navigation } = props;
  return (
    <NavigationStyled.Wrapper>
      {navigation &&
        navigation.map((navigationItem, index) => {
          return (
            <Navigation
              items={navigationItem.items}
              subMenuWidth={subMenuWidth}
              key={index}
            >
              {({ setNavigationOpen, isOpen }) => {
                if (navigationItem.link) {
                  return (
                    <NavigationStyled.MainLink
                      role="button"
                      variant={variant}
                      isOpen={false}
                      href={navigationItem.link}
                      data-tracking="header-nav"
                    >
                      <NavigationStyled.MainButtonText>
                        {navigationItem.label}
                      </NavigationStyled.MainButtonText>
                    </NavigationStyled.MainLink>
                  );
                }

                return (
                  <NavigationStyled.MainButton
                    type="button"
                    onMouseOver={() => setNavigationOpen(true)}
                    onClick={() => setNavigationOpen(true)}
                    isOpen={isOpen}
                    variant={variant}
                  >
                    <NavigationStyled.MainButtonText>
                      {navigationItem.label}
                    </NavigationStyled.MainButtonText>
                  </NavigationStyled.MainButton>
                );
              }}
            </Navigation>
          );
        })}
    </NavigationStyled.Wrapper>
  );
};

export { HeaderNavigation };
