import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const ListWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  margin: 0;
  z-index: 10;
`;

const List = styled.ul`
  box-shadow: 0px 1px 4px 0px rgba(51, 51, 51, 0.24);
  border-radius: ${({ theme }) => theme.borderRadius.default};
  background-color: ${({ theme }) => theme.colors.WHITE};
  width: 272px;
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.spacing.S8} 0px 0px 0px;
  border-radius: ${({ theme }) =>
    `${theme.borderRadius.default};
    `};
  :first-child {
    padding-top: ${({ theme }) => theme.spacing.M16};
  }
  :last-child {
    padding-bottom: ${({ theme }) => theme.spacing.M16};
  }
`;

const ElementWrapper = styled.div`
  position: relative;
  width: fit-content;

  * {
    box-sizing: border-box;
  }
`;

export { Wrapper, ListWrapper, List, ElementWrapper };
