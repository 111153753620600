import React, { forwardRef } from 'react';
import { USER_MENU } from 'components/Layouts/components/UserMenu';

import * as Styled from 'components/Layouts/Headers/HeaderComponents/HeaderUserDropdown/HeaderUserDropdown.styled';
import { HeaderUserDropdownProps } from 'components/Layouts/Headers/HeaderComponents/HeaderUserDropdown/HeaderUserDropdown.typed';
import * as UserDropdownStyled from 'components/Layouts/Headers/HeaderComponents/UserDropdown/UserDropdown.styled';
import { getMenuItems } from 'components/Layouts/components/UserMenuItems.helper';
import type { MenuItem } from 'components/Toolkit/Menu/Menu';

const HeaderUserDropdown = forwardRef<HTMLDivElement, HeaderUserDropdownProps>(
  (
    {
      isOpen,
      setIsOpen,
      selected,
      unreadMessageCount,
      unreadHistoryCheckCount,
      posX,
      logout,
    },
    ref,
  ) => {
    const handleLogout = () => {
      setIsOpen(false);
      logout();
    };

    const menuItems: MenuItem[] = getMenuItems({
      historyChecks: unreadHistoryCheckCount,
      messages: unreadMessageCount,
      showMore: false,
    });

    menuItems.push({
      id: USER_MENU.HELP,
      selected: selected === USER_MENU.HELP,
      displayName: 'Help',
      link: `https://hello.donedeal.ie/hc/en-us/`,
      newTab: true,
      divider: true,
      itemHidden: false,
    });

    menuItems.push({
      id: USER_MENU.USER_LOGOUT,
      selected: selected === USER_MENU.USER_LOGOUT,
      displayName: 'Log out',
      onClick: handleLogout,
      newTab: true,
      itemHidden: false,
    });

    return isOpen ? (
      <Styled.ListWrapper
        posX={posX}
        ref={ref}
        data-testid="header-dropdown-menu"
      >
        <Styled.List>
          {menuItems.map((item, index) => {
            const dataTestId = item.selected
              ? 'menu-item-selected'
              : 'menu-item';
            if (item.link) {
              if (item.divider) {
                return (
                  <>
                    <Styled.Separator />
                    <li key={index} data-testid={dataTestId}>
                      <Styled.ListItemLink href={item.link}>
                        {item.displayName}
                      </Styled.ListItemLink>
                    </li>
                  </>
                );
              } else {
                return (
                  <li key={index} data-testid={dataTestId}>
                    <Styled.ListItemLink href={item.link}>
                      <Styled.ListItemIcon>{item.icon}</Styled.ListItemIcon>
                      {item.displayName}
                      {item.notifications && (
                        <UserDropdownStyled.Badge count={item.notifications} />
                      )}
                    </Styled.ListItemLink>
                  </li>
                );
              }
            } else {
              return (
                <li
                  key={index}
                  data-testid={
                    item.selected ? 'menu-item-selected' : 'menu-item'
                  }
                >
                  <Styled.ListItemButton
                    onClick={item.onClick}
                    data-testid={dataTestId}
                  >
                    {item.displayName}
                  </Styled.ListItemButton>
                </li>
              );
            }
          })}
        </Styled.List>
      </Styled.ListWrapper>
    ) : null;
  },
);

HeaderUserDropdown.displayName = 'HeaderUserDropdown';

export { HeaderUserDropdown };
