import styled, { css, keyframes } from 'styled-components';
import { Link } from 'components/Toolkit/Button/Link';

export const NavigationAnimationY = keyframes`
  from {
    transform: translateY(-1rem);
    opacity: 0;
  }

  to {
    transform: translateY(0rem);
    opacity: 1;
  }
`;

const ItemStyles = css`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  padding: ${({ theme }) => theme.spacing.S8}
    ${({ theme }) => theme.spacing.M24};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.S8} 0;
`;

const SubList = styled.ul`
  background-color: ${({ theme }) => theme.colors.WHITE};
  border-radius: ${({ theme }) => theme.borderRadius.default};
  position: fixed;
  opacity: 1;
  left: 0;
  top: 0;
  flex-direction: column;
  width: 100%;
  box-shadow: none;
  display: flex;
  overflow-y: scroll;
  padding: ${({ theme }) => theme.spacing.M24};
  z-index: 99999;
  height: 100vh;
  animation: 150ms cubic-bezier(0, 0.76, 0.47, 1) 0s 1 normal none running
    ${NavigationAnimationY};
`;

const Item = styled.li`
  cursor: pointer;
  word-break: break-word;
  width: 100%;
  align-items: center;
  display: flex;
  white-space: nowrap;
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY_DARKER};
  &.last-item {
    a {
      color: ${({ theme }) => theme.colors.BLUE};
      &:hover,
      &:focus {
        text-decoration: underline;
        text-underline-offset: ${({ theme }) => theme.spacing.S4};
        color: ${({ theme }) => theme.colors.BLUE_DARKER};
        background: transparent;
      }
    }
  }
`;

const ItemBack = styled.li`
  cursor: pointer;
  ${ItemStyles};
  justify-content: flex-start;
  ${({ theme }) => theme.fontSize.M14};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding: ${({ theme }) => theme.spacing.S8} 0;
`;

const ItemBackIcon = styled.span`
  margin-left: -${({ theme }) => theme.spacing.S4};
  padding-right: ${({ theme }) => theme.spacing.S8};
`;

const ItemLabel = styled.span`
  ${ItemStyles};
`;

const ItemLink = styled.a`
  ${ItemStyles};
  justify-content: start;
`;

const ItemLastLink = styled(Link)`
  padding: ${({ theme }) => theme.spacing.S8};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing.S8} 0;
`;

const ItemExtraLabel = styled.span`
  ${({ theme }) => theme.fontSize.M16};
  color: ${({ theme }) => theme.colors.GREY};
  padding-left: ${({ theme }) => theme.spacing.S4};
`;

export {
  Item,
  SubList,
  ItemLabel,
  ItemLink,
  ItemExtraLabel,
  ItemBack,
  ItemBackIcon,
  ItemLastLink,
};
